//generateSpecificCards.js

function generateSpecificCards() {
    let twoD_array_cards=[]
    twoD_array_cards=[["A♠","K♠","Q♠","J♠","T♠","9♠","8♠","7♠","6♠","5♠","4♠","3♠","2♠"], 
    ["A♣","K♣","Q♣","J♣","T♣","9♣","8♣","7♣","6♣","5♣","4♣","3♣","2♣"], 
    ["A♥","K♥","Q♥","J♥","T♥","9♥","8♥","7♥","6♥","5♥","4♥","3♥","2♥"],
    ["A♦","K♦","Q♦","J♦","T♦","9♦","8♦","7♦","6♦","5♦","4♦","3♦","2♦"]]

    // twoD_array_cards=[["As","Ks","Qs","Js","Ts","9s","8s","7s","6s","5s","4s","3s","2s"],
    // ["Ac","Kc","Qc","Jc","Tc","9c","8c","7c","6c","5c","4c","3c","2c"],
    // ["Ah","Kh","Qh","Jh","Th","9h","8h","7h","6h","5h","4h","3h","2h"],
    // ["Ad","Kd","Qd","Jd","Td","9d","8d","7d","6d","5d","4d","3d","2d"]]
    console.log(twoD_array_cards);
    return twoD_array_cards;
  }

export default generateSpecificCards;