// //App.js

import React, { useState } from "react";
import Button from "./Button";
import { HeroLayout, VillainLayout, CommunityCards,ResultsZone} from "./grid";
//import generatePocketCards from "./generatePocketCards";
import logo from './logo.svg';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

function App() {

  const [equity,setEquity] = useState("")
  const [runWasClicked,setRunWasClicked]=useState(false)
  const [showCommunityCards,setShowCommunityCards] = useState(false); 

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Free poker equity calculator online
        </p>
        <HeroLayout />
        <VillainLayout/>
        <CommunityCards runWasClicked={runWasClicked} showCommunityCards={showCommunityCards} setShowCommunityCards={setShowCommunityCards}/>
        <ResultsZone setEquity={setEquity} equity={equity} runWasClicked={runWasClicked} setRunWasClicked={setRunWasClicked} showCommunityCards={showCommunityCards}/>
        {/* <DisplayResults equity={equity}/> */}
      </header>
    </div>
  );
}

export default App;