//grid.js

import generatePocketCards from './generatePocketCards';
import generateSpecificCards from './generateSpecificCards';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React,{useState,useEffect} from "react";
import "./Container.css";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import axios from "axios"; //API call
import Spinner from 'react-bootstrap/Spinner';




//import Button from 'react-bootstrap/Button' //watch out, we called button (with lowercase b that are not buttons from react-bootstrap)

export function HeroLayout({runWasClicked}) {

  const twoD_array_ranges = generatePocketCards();
  const twoD_array_cards=generateSpecificCards();

  const [focusedButtons, setFocusedButtons] = useState([]);
  const [showRanges, setShowRanges] = useState(false);

  const [focusedCards, setFocusedCards] = useState([]);
  const [showCards,setShowCards] = useState(false); 
  
  useEffect(() =>{
  console.log("focusedButtons", focusedButtons);
  console.log("showCards", showCards);
  }, [focusedButtons,showCards]);
  
  const handleButtonClick = (name) => {
    if (focusedButtons.includes(name)) { //removed from focusedButtons if a already focused button
      //is clicked again
      setFocusedButtons(focusedButtons.filter(buttonName=>buttonName !==name)); 
      }
    else{ //add to focusedButtons
      setFocusedButtons([...focusedButtons,name]);
      }
    }

  const handleCardClick = (name) => {
    if (focusedCards.includes(name)) { //removed from focusedButtons if a already focused button
      //is clicked again
      setFocusedCards(focusedCards.filter(cardName=>cardName !==name)); 
      }
    else{ //add to focusedButtons
      setFocusedCards([...focusedCards,name]);
      }
    }

  const ClickedPredefinedRange = (buttonClicked) => {
    setFocusedButtons(RangeClickedGetPredefinedRange(buttonClicked));
  }

    return (
     <Container className='container-hero'>
        <button className="modern-button-SelectRange-SelectCards" onClick={()=>{
          setShowRanges(!showRanges);
          if (showRanges===true){
            var Hero_or_Villain;
            console.log("getAllSequences function is called")
            getAllSequences(focusedButtons,Hero_or_Villain="Hero");

          }
        }}
        style={{display: showCards ? "none":"block"}}
        >{showRanges ? "Confirm":"Select Range"}
        </button>

        <button className="modern-button-SelectRange-SelectCards" onClick={()=>{
          if (focusedCards.length===0){
            setShowCards(!showCards)
          }
          
          
          else if (focusedCards.length===2){
            window.confirmedCardsHero=focusedCards;
            console.log("confirmedCardsHero",window.confirmedCardsHero) //we use window.confirmedCardsHero so that confirmedCardsHero is declared as a global variable
            setShowCards(!showCards);
            if (focusedCards.length===2){

            }
            }

          else {
            alert("Please select two cards or use Select Range instead")
          }
        }}
        
        style={{display: showRanges ? "none":"block"}}
        >{showCards ? "Confirm":"Select Cards"}</button>
        
        {/* if showRanges is true, call function displayButtonsAsGrid with twoD_array_ranges*/}
        {showRanges && displayButtonsAsGrid(twoD_array_ranges, handleButtonClick,showRanges,focusedButtons)}
        {showRanges && PredefinedRangesAsButtonsGroup(ClickedPredefinedRange)}
        
        {/* if showCards is true, call function displayButtonsAsGrid with twoD_array_cards*/}

        {showCards && displayButtonsAsGrid(twoD_array_cards, handleCardClick,showCards,focusedCards,window.confirmedCardsVillain)}
        Hero

      </Container>
    );
  }


  export function VillainLayout({equity,setEquity}) {

    const twoD_array_ranges = generatePocketCards();
    const twoD_array_cards=generateSpecificCards();
  
    const [focusedButtons, setFocusedButtons] = useState([]);
    const [showRanges, setShowRanges] = useState(false);
  
    const [focusedCards, setFocusedCards] = useState([]);
    const [showCards,setShowCards] = useState(false); 
    
    useEffect(() =>{
    console.log("focusedButtons", focusedButtons);
    console.log("showCards", showCards);
    }, [focusedButtons,showCards]);
    
    const handleButtonClick = (name) => {
      if (focusedButtons.includes(name)) { //removed from focusedButtons if a already focused button
        //is clicked again
        setFocusedButtons(focusedButtons.filter(buttonName=>buttonName !==name)); 
        }
      else{ //add to focusedButtons
        setFocusedButtons([...focusedButtons,name]);
        }
      }
  
    const handleCardClick = (name) => {
      if (focusedCards.includes(name)) { //removed from focusedButtons if a already focused button
        //is clicked again
        setFocusedCards(focusedCards.filter(cardName=>cardName !==name)); 
        }
      else{ //add to focusedButtons
        setFocusedCards([...focusedCards,name]);
        }
      }
  
    const ClickedPredefinedRange = (buttonClicked) => {
      setFocusedButtons(RangeClickedGetPredefinedRange(buttonClicked));
      }
  
      return (
       <Container className='container-villain'>
          <button className="modern-button-SelectRange-SelectCards" onClick={()=>{
            
            setShowRanges(!showRanges);
            if (showRanges===true){
              var Hero_or_Villain;
              getAllSequences(focusedButtons,Hero_or_Villain="Villain");
            }
          }}
          style={{display: showCards ? "none":"block"}}
          >{showRanges ? "Confirm":"Select Range"}
          </button>
  
          <button className="modern-button-SelectRange-SelectCards" onClick={()=>{
            console.log("focusedCards",focusedCards)
            if (focusedCards.length===0){
            setShowCards(!showCards);
            }
            else if (focusedCards.length===2){
  
              window.confirmedCardsVillain=focusedCards
              
              console.log("confirmedCardsVillain",window.confirmedCardsVillain) //we use window.confirmedCardsVillain so that confirmedCardsVillain is declared as a global variable
              console.log("confirmedCardsHero",window.confirmedCardsHero)//
              setShowCards(!showCards);
            }
            else {
              alert("Please select two cards or use Select Range instead")
              }
            }}
            
          style={{display: showRanges ? "none":"block"}}
  
          >{showCards ? "Confirm":"Select Cards"}</button>
          
          
          {/* if showRanges is true, call function displayButtonsAsGrid with twoD_array_ranges*/}
          {showRanges && displayButtonsAsGrid(twoD_array_ranges, handleButtonClick,showRanges,focusedButtons)}
          {showRanges && PredefinedRangesAsButtonsGroup(ClickedPredefinedRange)}
  
  
          {/* if showCards is true, call function displayButtonsAsGrid with twoD_array_cards*/}
  
          {showCards && displayButtonsAsGrid(twoD_array_cards, handleCardClick,showCards,focusedCards,window.confirmedCardsHero)}
          
          Villain
        </Container>
      )
      }
  
export function CommunityCards({runWasClicked,showCommunityCards,setShowCommunityCards}) {
    
    const twoD_array_cards=generateSpecificCards();
    const [focusedCards, setFocusedCards] = useState([]);
    console.log("runWasClicked",runWasClicked)
    console.log("showCommunityCards",showCommunityCards)


    useEffect(() =>{
    //console.log("focusedButtons", focusedButtons);
    console.log("showCommunityCards", showCommunityCards);
    }, [showCommunityCards]);
  
    const handleCardClick = (name) => {
      if (focusedCards.includes(name)) { //removed from focusedButtons if a already focused button
        //is clicked again
        setFocusedCards(focusedCards.filter(cardName=>cardName !==name)); 
        }
      else{ //add to focusedButtons
        setFocusedCards([...focusedCards,name]);
        }
      }
      

      return (
      
       <Container className='container-community'>
          
          <button className="modern-button-SelectRange-SelectCards" onClick={()=>{
            if (focusedCards.length===0){
            setShowCommunityCards(!showCommunityCards);
            }
            else if (focusedCards.length<=5){
              window.confirmedCommunityCards=focusedCards
              console.log("confirmedCommunityCards",window.confirmedCommunityCards)//we use window.cconfirmedCommunityCards so that confirmedCommunityCards is declared as a global variable
              setShowCommunityCards(!showCommunityCards);

              
            }
            else {
              alert("You cannot select more than 5 cards as community cards")
            }
            

            // if (window.confirmedCommunityCards){
            //   let isThereADuplicate=checkForCardsDuplicates();
            //   if (isThereADuplicate!==false){
            //   alert("A duplicate was found. Please check Hero Cards, Villain Cards and Community Cards. No card can be found twice")
            //   }
            // }
          }}
          
          >{showCommunityCards ? "Confirm":"Select Cards"}</button>
          
          {/* if showCards is true, call function displayButtonsAsGrid with twoD_array_cards*/}
          {showCommunityCards && displayButtonsAsGrid(twoD_array_cards, handleCardClick,showCommunityCards,focusedCards,window.confirmedCardsHero+window.confirmedCardsVillain)}
          {showCommunityCards ? "":"Community Cards"}
          {/*Community Cards*/}
        </Container>
      );
    }

function getAllSequences(focusedButtons,Hero_or_Villain){

  //diagonal of pocketPairs
  const pocketPairs=["AA","KK","QQ","JJ","TT","99","88","77","66","55","44","33","22"];
  console.log("pocketPairs",pocketPairs);
  //all rows above diagonal of pocketPairs
  var suitedArrays=[
  ["AKs","AQs","AJs","ATs","A9s","A8s","A7s","A6s","A5s","A4s","A3s","A2s"], 
  ["KQs","KJs","KTs","K9s","K8s","K7s","K6s","K5s","K4s","K3s","K2s"],
  ["QJs","QTs","Q9s","Q8s","Q7s","Q6s","Q5s","Q4s","Q3s","Q2s"], 
  ["JTs","J9s","J8s","J7s","J6s","J5s","J4s","J3s","J2s"],
  ["T9s","T8s","T7s","T6s","T5s","T4s","T3s","T2s"], 
  ["98s","97s","96s","95s","94s","93s","92s"],
  ["87s","86s","85s","84s","83s","82s"], 
  ["76s","75s","74s","73s","72s"],
  ["65s","64s","63s","62s"],      
  ["54s","53s","52s"], 
  ["43s","42s"],
  ["32s"]];

  console.log("suitedArrays",suitedArrays);

  //all rows under diagonal of pocketPairs
  var offsuitedArrays=[
  ["AKo","AQo","AJo","ATo","A9o","A8o","A7o","A6o","A5o","A4o","A3o","A2o"], 
  ["KQo","KJo","KTo","K9o","K8o","K7o","K6o","K5o","K4o","K3o","K2o"],
  ["QJo","QTo","Q9o","Q8o","Q7o","Q6o","Q5o","Q4o","Q3o","Q2o"],
  ["JTo","J9o","J8o","J7o","J6o","J5o","J4o","J3o","J2o"],
  ["T9o","T8o","T7o","T6o","T5o","T4o","T3o","T2o"], 
  ["98o","97o","96o","95o","94o","93o","92o"],
  ["87o","86o","85o","84o","83o","82o"], 
  ["76o","75o","74o","73o","72o"],
  ["65o","64o","63o","62o"],     
  ["54o","53o","52o"], 
  ["43o","42o"],
  ["32o"]];

  console.log("offsuitedArrays",offsuitedArrays);
  //call of functions to reduce buttons to ranges
  let allSequences=[]; 
  let arrayToCheck=undefined;
  allSequences=focusedButtonsAsRange(focusedButtons,allSequences,arrayToCheck=pocketPairs); //function call
  
  //suited arrays
  for (let arrayIndex=0;arrayIndex<suitedArrays.length;arrayIndex++) {
    allSequences=focusedButtonsAsRange(focusedButtons,allSequences,arrayToCheck=suitedArrays[arrayIndex]);
  }
  //offsuited arrays
  for (let arrayIndex=0;arrayIndex<offsuitedArrays.length;arrayIndex++) {
    allSequences=focusedButtonsAsRange(focusedButtons,allSequences,arrayToCheck=offsuitedArrays[arrayIndex]);
  }
  if (Hero_or_Villain==="Hero"){

    if (allSequences.length>0){
    window.confirmedRangesHero=allSequences;
    console.log("window.confirmedRangesHero",window.confirmedRangesHero);
    }
  }
  else if (Hero_or_Villain==="Villain"){
    //let allRangesVillain=allSequences;
    if (allSequences.length>0) {
    window.confirmedRangesVillain=allSequences;
    console.log("window.confirmedRangesVillain",window.confirmedRangesVillain);
    }
  }
  else {
    alert("The Hero_or_Villain argument was not detected properly!!!")
  }
}


function focusedButtonsAsRange(focusedButtons,allSequences,arrayToCheck) {
  let startSeq=undefined; //We need to initialize startSeq and endSeq for these variables to be accessible in the console log outside the for loops
  let endSeq=undefined;
  let currentIndex = 0;
  let allStartSequences = [];
  let allEndSequences = [];
  
  while (currentIndex < arrayToCheck.length) {
      if (focusedButtons.includes(arrayToCheck[currentIndex])) {
        startSeq=arrayToCheck[currentIndex];
        allStartSequences.push(startSeq);
        for (let j=currentIndex+1;j<=arrayToCheck.length;j++) {
          if (!focusedButtons.includes(arrayToCheck[j])) {
            if(j === arrayToCheck.length-1 && focusedButtons.includes(arrayToCheck[j])){
                endSeq = arrayToCheck[j];
                allEndSequences.push(endSeq);
                currentIndex=j+1;
                break;
            }else{
                endSeq=arrayToCheck[j-1];
                allEndSequences.push(endSeq);
                currentIndex=j;
                break;
            }
          }
        }
      }
      currentIndex++;
    }


  for (let i=0;i<allStartSequences.length;i++) {
    if (i===0 && allStartSequences[0]===arrayToCheck[0] && allStartSequences[0]!==allEndSequences[0]) {
      allSequences.push(allEndSequences[0]+"+");
    } else if (allStartSequences[i]===allEndSequences[i]) { 
      allSequences.push(allStartSequences[i]);
    } else{
      allSequences.push(allStartSequences[i]+"-"+allEndSequences[i]);
    }
    }

  return allSequences; 
  }


function displayButtonsAsGrid(twoDimensionalArray, handleClick, show, focusedButtons,buttonsToDisable=[]) {
  return (
    <Container>
      {twoDimensionalArray.map((row, rowIndex) => (
        <Row key={rowIndex}>
          <Container className="container-one-row">
          {row.map((element, colIndex) => (
            <Col key={colIndex}>
              <button
                className={`modern-button ${focusedButtons.includes(element) ? 'focused' : ''}`}
                onClick={() => handleClick(element)}
                style={{ display: show ? "block" : "none" }}
                disabled={buttonsToDisable.length > 0 && buttonsToDisable.includes(element) ? true : false}
              >
                {element}
              </button>
            </Col>
          ))}
          </Container>
          
        </Row>
      ))}
    </Container>
  );
}


function checkForCardsDuplicates(){
  let arrayToCheck=false;
  if (window.confirmedCardsHero && window.confirmedCardsVillain){
    arrayToCheck=[...window.confirmedCardsHero,...window.confirmedCardsVillain]
    console.log("arrayToCheck top",arrayToCheck)

  }

  else if (window.confirmedCardsHero && window.confirmedCardsVillain && window.confirmedCommunityCards){
    arrayToCheck=[...window.confirmedCardsHero,...window.confirmedCardsVillain,...window.confirmedCommunityCards]
    console.log("arrayToCheck top",arrayToCheck)
  }

  else if (window.confirmedCommunityCards){
    console.log("Only communityCards was confirmed, no array is checked for duplicates")
  }
  
  // Create an empty object to store unique values
  const uniqueValues = {};
  // Iterate over  list
  console.log("arrayToCheck bottom",arrayToCheck)
  if (arrayToCheck){
    console.log("arrayToCheck is true thus a code is run")
    for (let value of arrayToCheck) {
      // If the value is already in the object, return true (duplicate found)
      if (uniqueValues[value]) {
        return true;
      } 
    }
  }
  // If no duplicates are found in array to check or if no array to check, return false
  return false;
}

function PredefinedRangesAsButtonsGroup(ClickedPredefinedRange){
  return (
    <div><h3>Predefined Ranges</h3>
    <ButtonGroup vertical> 
    <button className="modern-button-predefined-ranges" onClick={()=>ClickedPredefinedRange("EP")}>EP</button>
    <button className="modern-button-predefined-ranges" onClick={()=>ClickedPredefinedRange("LJ")}>LJ</button>
    <button className="modern-button-predefined-ranges" onClick={()=>ClickedPredefinedRange("HJ")}>HJ</button>
    <button className="modern-button-predefined-ranges" onClick={()=>ClickedPredefinedRange("CO")}>CO</button>
    <button className="modern-button-predefined-ranges" onClick={()=>ClickedPredefinedRange("BU")}>BU</button>
    <button className="modern-button-predefined-ranges" onClick={()=>ClickedPredefinedRange("SB")}>SB</button>
    </ButtonGroup>
    </div>
  );
}

function RangeClickedGetPredefinedRange(buttonClicked) {
  let PredefinedRange;
  if (buttonClicked==="EP"){
    PredefinedRange=["AA","KK","QQ","JJ","TT","99","88","77","AKs","AQs","AKo","AQo"];
  } else if (buttonClicked==="LJ"){
    PredefinedRange=["AA","KK","QQ","JJ","TT","99","88","77","AKs","AQs","AJs","KQs","QJs","JTs","T9s","98s","AKo","AQo"];
  } else if (buttonClicked==="HJ"){
    PredefinedRange=["AA","KK","QQ","JJ","TT","99","88","77","66","55","AKs","AQs","AJs","ATs","KQs","KJs","QJs","JTs","T9s","98s","87s","76s","AKo","AQo","AJo","ATo","KQo"];
  } else if (buttonClicked==="CO"){
    PredefinedRange=["AA","KK","QQ","JJ","TT","99","88","77","66","55","44","33","22","AKs","AQs","AJs","ATs","A9s","A8s","A7s","KQs","KJs","KTs","K9s","QJs","QTs","Q9s","JTs","J9s","T9s","T8s","98s","97s",
    "87s","76s","65s","54s","AKo","AQo","AJo","ATo","A9o","KQo","KJo","KTo","QJo","QTo","JTo","T9o","98o","87o"];
  } else if (buttonClicked==="BU"){
    PredefinedRange=["AA","KK","QQ","JJ","TT","99","88","77","66","55","44","33","22","AKs","AQs","AJs","ATs","A9s","A8s","A7s","A6s","A5s","A4s","A3s","A2s","KQs","KJs","KTs","K9s","QJs","QTs","Q9s","JTs","J9s","J8s",
    "T9s","T8s",
    "98s","97s",
    "87s","86s",
    "76s","75s"
    ,"65s","54s",
    "AKo","AQo","AJo","ATo","A9o","A8o","A7o","A6o","A5o","A4o","A3o","A2o",
    "KQo","KJo","KTo",
    "QJo","QTo",
    "JTo","J9o",
    "T9o","T8o",
    "98o","87o","76o","65o"];
  } else if (buttonClicked==="SB"){
    PredefinedRange=["AA","KK","QQ","JJ","TT","99","88","77","66","55","44","33","22",
    "AKs","AQs","AJs","ATs","A9s","A8s","A7s","A6s","A5s","A4s","A3s","A2s",
    "KQs","KJs","KTs","K9s",
    "QJs","QTs","Q9s",
    "JTs","J9s",
    "T9s",
    "98s",
    "87s",
    "76s",
    "65s","54s",
    "AKo","AQo","AJo","ATo",
    "KQo","KJo","KTo",
    "QJo","QTo",
    "JTo"];
  }
  return PredefinedRange
}


function ApiCall(setEquity,setRunWasClicked){
    setRunWasClicked(true) //for Button that triggers Calculate
    setEquity("Calculating...")
    //change Unicode here. Change "h" for heart, "d" for diamond, "c" for clubs, "s" for spades
    var cardUnicode = {
      '♠': 's',
      '♣': 'c',
      '♥': 'h',
      '♦': 'd'
    }

    if (window.confirmedCardsHero){
    var cardsHero = window.confirmedCardsHero.map(card => card.replace(/[♠♣♥♦]/g, unicode => cardUnicode[unicode]))
    console.log("cardsHero", cardsHero)
    }

    if (window.confirmedCardsVillain){
      var cardsVillain = window.confirmedCardsVillain.map(card => card.replace(/[♠♣♥♦]/g, unicode => cardUnicode[unicode]))
      console.log("cardsVillain", cardsVillain)
    }

    if (window.confirmedCommunityCards){
      var cardsCommunityCards = window.confirmedCommunityCards.map(card => card.replace(/[♠♣♥♦]/g, unicode => cardUnicode[unicode]))
      console.log("cardsCommunityCards", cardsCommunityCards)
    }
    
    if (window.confirmedRangesHero){
      console.log("confirmedRangesHero",window.confirmedRangesHero)
    }

    if (window.confirmedRangesVillain){
      console.log("confirmedRangesVillain",window.confirmedRangesVillain)
    }

    const payload = {}
    
    if (cardsHero){
      payload.confirmedCardsHero=cardsHero; 
    }
    if (cardsVillain) {
      payload.confirmedCardsVillain=cardsVillain; 
    }
    if (window.confirmedRangesHero){
      payload.confirmedRangesHero=window.confirmedRangesHero
    }
    if (window.confirmedRangesVillain){
      payload.confirmedRangesVillain=window.confirmedRangesVillain
    }
    if (cardsCommunityCards){
      payload.confirmedCommunityCards=cardsCommunityCards
    }
    console.log("payload",payload)
    console.log("An ApiCall() is made!!!")
    axios.post("https://9i3vlqee96.execute-api.us-east-1.amazonaws.com/POSTreceived",payload)
    .then(function(response){
      const equity_calc=response.data.equity_calc;
      const hands_history_length=response.data.hands_history_length
      console.log("equity_calc",equity_calc)//do smth with response
      console.log("hands_history_length",hands_history_length)
      setEquity(equity_calc.toFixed(2)) //toFixed to set decimals to two decimals
      console.log("setEquity was successful!!!")
      setRunWasClicked(false)//for Button that triggers Calculate
    })

    .catch(function(error){
      console.log("in function error")//handle the error
      setRunWasClicked(false)//for Button that triggers Calculate
    });


  console.log("Axios end")
  console.log("window.equity_calc",window.equity_calc)
  console.log("Axios end after DisplayResults call")

}

function DisplayResults({equity}){

  console.log("Display Results is called")
  console.log("Just before return!")
  if (equity!==""){
  return (

    <div>Hero Equity: {equity} %</div>

    )
  }
}

export function ResultsZone({setEquity,equity,runWasClicked,setRunWasClicked,showCommunityCards}) {

  const handleClick = () => {
    if (showCommunityCards===true){
      alert("Please confirm cards before running simulation")
      return; //return nothing so it doesnt call ApiCall() 
    }

    let isThereADuplicate=checkForCardsDuplicates(window.confirmedCardsHero,window.confirmedCardsVillain,window.confirmedCommunityCards)
    if (isThereADuplicate===true){
      alert("A duplicate was found! Please check again Hero Cards, Villain cards and Community Cards")
    }


    if ((window.confirmedCardsHero || window.confirmedRangesHero) && (window.confirmedCardsVillain || window.confirmedRangesVillain)) 
    {
      console.log("confirmedCardsHero in API", window.confirmedCardsHero);
      console.log("confirmedCardsVillain in API", window.confirmedCardsVillain);
      console.log("confirmedCommunityCards in API", window.confirmedCommunityCards);
      ApiCall(setEquity,setRunWasClicked);
    } else {
      console.log(
        "ApiCall() was attempted but not triggered. Check if Hero and Villain has cards or range"
      );
    }
  };

  return (
    <Container className="container-results">
    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
    <button
      className="modern-button-Calculate"
      onClick={handleClick}
    >
      {runWasClicked ? <Spinner animation="border" variant="danger"/>: "Calculate"}
    </button>
    </div>
    {equity !== "" ? <DisplayResults equity={equity} /> : null}
    </Container>
  );
}

























