//Button.js

import React from "react";
import "./Button.css"

class Button extends React.Component{
    constructor(name) {
    //Call the super class constructor 
    super();
    //Set the name property of the instance
    this.name = name;
    }
  
    render() {
      return <button className="modern-button">{this.name}</button>;
    }
}

export default Button;

